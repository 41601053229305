(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("dashboardServerHealth", dashboardServerHealth);

	function dashboardServerHealth($rootScope, $scope, $http, coreData, $filter, $stateParams, authStorage, coreDataDomainSettings, $timeout, signalrHubManager, $state, userTimeService, errorHandling) {
		var vm = this;
		var serverHealthCards = getDashboardCardDefinition();
		var processStartTime = null;
		var initialized = false;
		vm.connection = null;
		$scope.charts = {};
		$scope.reportCards = serverHealthCards;
		$scope.navigateTo = navigateTo;

		// Functions
		$scope.navigateBack = navigateBack;

		activate();

		//////////////////////

		function activate() {
			$rootScope.$state.current.helpid = `index.reports.system.dashboard`;

			if (!coreData.user.isSysAdmin) { $scope.isSysAdmin = false; return; }
			$scope.isSysAdmin = true;
			userTimeService
				.init()
				.then(function() {
						$scope.$on('signalR.client.dashboardUpdate', onDashboardUpdate);
						$scope.$on('signalR.client.dashboardInit', onDashboardInit);
						$timeout(function() {
								$(window).trigger('resize');
								$rootScope.$broadcast("masonry:contentsChanged");
							},
							50);
						connectToSignalR();
						$timeout(updateSystemUptime, 1000);
					},
					errorHandling.report);
		}

		function navigateBack() {
			$state.go('index.sysadmin-reports', {});
		}

		function connectToSignalR() {
			signalrHubManager.init()
				.then(function (connections) {
					connections.mailHub.invoke("dashboardConnectWithStartData", authStorage.getToken(), ['cpu', 'memoryPerc', 'dnsInfo', 'threads', 'driveInfo', 'protocolActivity', 'spam', 'deliveries', 'threads', 'greylist', 'spoolcount', 'cores', 'totalram', 'opsys', 'container'], ['systeminfo', 'historyCpuMem1Hour'])
						.then(function () {
							vm.connection = connections.mailHub;
							$timeout(dashboardUpdate, 2000);
						});
				}, function () {});
		}

		function updateSystemUptime() {
			$timeout(updateSystemUptime, 1000);
			if (processStartTime) {
				var now = moment();
				var diff = now.diff(processStartTime);
				$scope.charts["REPORT_DASHBOARD_SERVICE_UPTIME"].updateValue($filter("date")(convertLocalToUserTime(processStartTime), "medium"));
				$scope.charts["REPORT_DASHBOARD_SERVICE_UPTIME"].updateTotal($filter("translate")("REPORT_DASHBOARD_SERVICE_UPTIME_OUTPUT", { days: Math.abs(moment.duration(diff).days()), hours: Math.abs(moment.duration(diff).hours()), minutes: Math.abs(moment.duration(diff).minutes()) }));
			}
		}

		function convertLocalToUserTime(momentTime) {

			var jsDate = momentTime.toDate();
			var utc = jsDate.getTime() + (jsDate.getTimezoneOffset() * 60000);
			var offset = moment.tz(momentTime, userTimeService.userTimeZone.location).utcOffset();
			return new Date(utc + (offset * 60000));
		}

		function onDashboardInit(event, startData) {
			processStartTime =  moment(startData.processStartTime);
			if (!initialized)
				createDriveCards(startData.availableDrives, startData.isWindows);
			initialized = true;
			var now = moment();
			var diff = now.diff(processStartTime);
			$scope.reportCards[0].cardSubTitle = startData.machineName;
			$scope.charts["REPORT_DASHBOARD_SERVICE_UPTIME"].updateValue($filter("date")(convertLocalToUserTime(processStartTime), "medium"));

			var days = Math.abs(moment.duration(diff).days());
			var hours = Math.abs(moment.duration(diff).hours());
			var minutes = Math.abs(moment.duration(diff).minutes());
			$scope.charts["REPORT_DASHBOARD_SERVICE_UPTIME"].updateTotal($filter("translate")("REPORT_DASHBOARD_SERVICE_UPTIME_OUTPUT", { days: days, hours: hours, minutes: minutes }));

			var dataPoints = 50;
			//if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream)
			//	dataPoints = 50; //Less data points for iOS, otherwise it gets laggy.

			var values = new Array();
			for (var i = 0; i < startData.cpuHistory[0].length; i++) {
				for (var x = 0; x < dataPoints; x++) {
					values.push([startData.cpuHistory[0][i]]);
				}
			}
			$scope.charts["CPU_USAGE"].updateValues(values);

			values = new Array();
			for (var j = 0; j < startData.memHistory[0].length; j++) {
				for (var y = 0; y < dataPoints; y++) {
					values.push([(startData.memHistory[0][j] / startData.totalRam)]);
				}
			}
			$scope.charts["REPORT_DASHBOARD_MEMORY_USAGE"].updateValues(values);
		}

		function dashboardUpdate() {
			if (vm.connection)
				vm.connection.invoke("dashboardUpdate", authStorage.getToken(), ['cpu', 'memoryPerc', 'dnsInfo', 'threads', 'driveInfo', 'cores', 'totalram', 'opsys', 'container'])
				.then(function (data) {
					$timeout(dashboardUpdate, 2000);
					onDashboardUpdate(null, data);
				});
		}

		function onDashboardUpdate(event, data) {
			$scope.$evalAsync(function () {
                if (!data)
					return;

				$scope.charts["CPU_USAGE"].updateValue([[data.cpu]]);
				$scope.charts["REPORT_DASHBOARD_MEMORY_USAGE"].updateValue([[data.memoryPerc]]);
				$scope.charts["HITS"].updateValue(data.dnsCacheHits);
				$scope.charts["MISSES"].updateValue(data.dnsCacheMisses);
				$scope.charts["MISSES"].updateTotal(data.dnsCacheTotalItems);

				var imap = Number.isInteger(data.imapThreads) ? data.imapThreads : 0;
				var pop = Number.isInteger(data.popThreads) ? data.popThreads : 0;
				var smtp = Number.isInteger(data.smtpThreads) ? data.smtpThreads : 0;
				var delivery = Number.isInteger(data.deliveryThreads) ? data.deliveryThreads : 0;
				var ldap = Number.isInteger(data.ldapThreads) ? data.ldapThreads : 0;
				var total = imap + pop + smtp + delivery + ldap;
				var ramGB = Math.floor(data.totalRam / 1000);

				if (data.isContainer) {
					var containerText = $filter("translate")("REPORT_DASHBOARD_CONTAINER");
					$scope.charts["REPORT_DASHBOARD_SYSTEMINFO"].updateValue(`${data.opSystem} / ${containerText}`);
				}
				else {
					$scope.charts["REPORT_DASHBOARD_SYSTEMINFO"].updateValue(data.opSystem);
				}

				$scope.charts["REPORT_DASHBOARD_TOTAL_RAM"].updateValue(ramGB);
				$scope.charts["REPORT_DASHBOARD_CORE_COUNT"].updateValue(data.cores);
				$scope.charts["IMAP"].updateValue(imap);
				$scope.charts["POP"].updateValue(pop);
				$scope.charts["SMTP"].updateValue(smtp);
				$scope.charts["REPORT_DASHBOARD_DELIVERY_THREADS"].updateValue(delivery);
				$scope.charts["REPORT_DASHBOARD_LDAP_CONNECTIONS"].updateValue(ldap);
				$scope.charts["REPORT_DASHBOARD_LDAP_CONNECTIONS"].updateTotal(total.toString());

				if (data.driveInfo)
					data.driveInfo.forEach(function (drive) {
						var foundChart = $scope.charts[`AvgReadWrite[${drive.letter}]`];
						if (!foundChart)
							return;

						foundChart.updateValue(`${drive.queue}`);
						foundChart.updateValue(`${drive.read} / ${drive.write}`);
						var diskFreeGB = Math.floor(drive.free / 1024);
						var diskTotalGB = Math.floor(drive.total / 1024);
						var diskFreeTB = (diskFreeGB / 1024).toFixed(2);
						var diskTotalTB = (diskTotalGB / 1024).toFixed(2);
						if (diskTotalTB < 1) {
							foundChart.updateValue(`${diskFreeGB} GB / ${diskTotalGB} GB`);
						} else {
							diskTotalTB = Math.floor(diskTotalTB);
							foundChart.updateValue(`${diskFreeTB} TB / ${diskTotalTB} TB`);
						}
						var used = diskTotalGB - diskFreeGB;
						$scope.charts[`DRIVE_${drive.letter}_AVERAGE_STATISTICS`].updateValues(used, diskTotalGB);
					});

				if (data.spoolCount)
					$scope.charts["REPORT_DASHBOARD_MESSAGES_IN_SPOOL"].updateValue(data.spoolCount.toString());
			});
		}

		function navigateTo(target) {
			if (!target)
				return;

			var reportName = target;
			if (target.toLowerCase().startsWith("drive_") && target.toLowerCase().endsWith("_average_statistics"))
				reportName = "drive_average_statistics";
			switch (reportName) {
				case "drive_average_statistics":
					$state.go('index.reports.reports-details', { type: "system_server_health", report: target.toLowerCase() });
					break;
				case "REPORT_DASHBOARD_MEMORY_USAGE":
				case "CPU_USAGE":
					$state.go('index.reports.reports-details', { type: "system_server_health", report: "average_hardware_usage" });
					break;
			}
		}

		function createDriveCards(driveList, isWindows) {
			for (var i = 0; i < driveList.length; i++) {
				var card = null;
				if (isWindows) {
					card = {
						"cardType": "dataCard",
						"cardTitle": `DRIVE_${driveList[i]}_AVERAGE_STATISTICS`,
						"cardDisplayTitle": {
							"title": $filter("translate")("REPORT_DASHBOARD_DRIVE_STATISTICS", { drive: driveList[i] }),
							"data": { 'drive': `${driveList[i]}` }
						},
						cardContents:
							[
								{
									"dataType": "label",
									"cardTitle": `AvgQueueLength[${driveList[i]}]`,
									"data": {
										"name": "AVG_QUEUE_LENGTH",
										"value": "0"
									}
								},
								{
									"dataType": "label",
									"cardTitle": `AvgReadWrite[${driveList[i]}]`,
									"data": {
										"name": "AVG_READ_WRITE",
										"value": "0"
									}
								},
								{
									"dataType": "label",
									"cardTitle": `FreeTotal[${driveList[i]}]`,
									"data": {
										"name": "REPORT_DASHBOARD_FREE_TOTAL",
										"value": "0"
									}
								},
								{
									'dataType': 'percentBar',
									'data': {
										'label': 'USED',
										'value': '0',
										'total': '1',
										'color': '#28c2ba'
									}
								}
							]
					};
				} else {
					card = {
						"cardType": "dataCard",
						"cardTitle": $filter("translate")("REPORT_DASHBOARD_DRIVE_STATISTICS", { drive: driveList[i] }),
						cardData: {
							value: ""
						},
						cardContents:
							[
								{
									"dataType": "label",
									"cardTitle": `AvgReadWrite[${driveList[i]}]`,
									"data": {
										"name": "AVG_READ_WRITE",
										"value": "0"
									}
								},
								{
									"dataType": "label",
									"cardTitle": `FreeTotal[${driveList[i]}]`,
									"data": {
										"name": "REPORT_DASHBOARD_FREE_TOTAL",
										"value": "0"
									}
								},
								{
									'dataType': 'percentBar',
									'data': {
										'label': 'USED',
										'value': '0',
										'total': '1',
										'color': '#28c2ba'
									}
								}
							]
					};
				}
				$scope.reportCards.push(card);
			}
		}

		function getDashboardCardDefinition() {
			return [
				// Service Uptime and DNS Items
				{
					cardType: "dataCard",
					cardTitle: "REPORT_DASHBOARD_SERVICE_UPTIME",
					cardData: {
						value: ""
					},
					cardContents:
					[
						{
							"dataType": "label_noName",
							"data": {
								"name": "UPTIME_DATE",
								"value": "N/A"
							}
						}
					]
				},
				{
					cardType: "dataCard",
					cardTitle: "REPORT_DASHBOARD_SYSTEMINFO",
					cardData: {
						value: ""
					},
					cardContents:
					[
						{
							"dataType": "label_noName",
							"data": {
								"name": "REPORT_DASHBOARD_SYSTEMINFO",
								"value": ""
							}
						},
						{
							"dataType": "label",
							"cardTitle": "REPORT_DASHBOARD_CORE_COUNT",
							"data": {
								"name": "REPORT_DASHBOARD_CORE_COUNT",
								"value": "0"
							}
						},
						{
							"dataType": "label",
							"cardTitle": "REPORT_DASHBOARD_TOTAL_RAM",
							"data": {
								"name": "REPORT_DASHBOARD_TOTAL_RAM",
								"value": "0"
							}
						},
					]
				},
				{
					"cardType": "dataCard",
					"cardTitle": "REPORT_DASHBOARD_PROTOCOL_ACTIVITY",
					"cardData": {
						"value": "0"
					},
					"cardContents": [
						// TODO:: Every card should probably use unit instead of hard coding the text, that way it can be translated
						{
							"dataType": "label",
							"cardTitle": "IMAP",
							"data": {
								"name": "IMAP",
								"value": "0"
							}
						},
						{
							"dataType": "label",
							"cardTitle": "POP",
							"data": {
								"name": "POP",
								"value": "0"
							}
						},
						{
							"dataType": "label",
							"cardTitle": "SMTP",
							"data": {
								"name": "SMTP",
								"value": "0"
							}
						},
						{
							"dataType": "label",
							"cardTitle": "REPORT_DASHBOARD_DELIVERY_THREADS",
							"data": {
								"name": "REPORT_DASHBOARD_DELIVERY_THREADS",
								"value": "0"
							}
						},
						{
							"dataType": "label",
							"cardTitle": "REPORT_DASHBOARD_LDAP_CONNECTIONS",
							"data": {
								"name": "REPORT_DASHBOARD_LDAP_CONNECTIONS",
								"value": "0"
							}
						}
					]
				},
				// DNS Cache Utilization
				{
					"cardType": "dataCard",
					"cardTitle": "DNS_CACHE_UTILIZATION",
					"cardData": {
						"value": "0"
					},
					"cardContents": [
						// TODO:: Every card should probably use unit instead of hard coding the text, that way it can be translated
						{
							"dataType": "label",
							"cardTitle": "HITS",
							"data": {
								"name": "HITS",
								"value": "0"
							}
						},
						{
							"dataType": "label",
							"cardTitle": "MISSES",
							"data": {
								"name": "MISSES",
								"value": "0"
							}
						}
					]
				},
				// Hardware Usage
				{
					"cardType": "dataCard",
					"cardTitle": "CPU_USAGE",
					"cardData": {
					},
					"cardContents": [
						{
							"dataType": "lineChart",
							"suffix": " %",
							"metric": "HOUR",
							"data": {
								"items": [
									"CPU"
								],
								"values": [
									["0"],
									["0"],
									["0"],
									["0"],
									["0"],
									["0"],
									["0"],
									["0"],
									["0"],
									["0"]
								]
							}
						}
					]
				},
				{
					"cardType": "dataCard",
					"cardTitle": "REPORT_DASHBOARD_MEMORY_USAGE",
					"cardData": {
					},
					"cardContents": [
						{
							"dataType": "lineChart",
							"suffix": " %",
							"metric": "HOUR",
							"colors": ["#e23f33"],
							"data": {
								"items": [
									"MEMORY"
								],
								"values": [
									["0"],
									["0"],
									["0"],
									["0"],
									["0"],
									["0"],
									["0"],
									["0"],
									["0"],
									["0"]
								]
							}
						}
					]
				},
				{
					"cardType": "pairedCard",
					"cardTitle": "COLORED_CARDS",
					"isPairedCard": true,
					"ignoreTitle": true,
					"cardContents":
					[
						[{
							"dataType": "colored",
							"cardTitle": "REPORT_DASHBOARD_MESSAGES_IN_SPOOL",
							"data":
							{
								"name": "REPORT_DASHBOARD_MESSAGES_IN_SPOOL",
								"value": "0",
								"color": "#5497a7"
							}
						}]
					]
				}
			];
		}
	}
})();
(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userCloudStorageDialogController", userCloudStorageDialogController);

	function userCloudStorageDialogController($scope, $mdDialog, type, name, allowDelete) {
		$scope.allowDelete = allowDelete;
		$scope.name = name;
		$scope.type = type;

		$scope.save = function () {
			$mdDialog.hide({ name: $scope.name, deleteItem: false });
		};

		$scope.delete = function () {
			$mdDialog.hide({ deleteItem: true });
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();

(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("systemSslCertificateOptionsController", systemSslCertificateOptionsController);

    function systemSslCertificateOptionsController($rootScope, $scope, $filter, $mdDialog, $q, $http, $state, $translate, $timeout,
        coreLicensing, errorHandling, successHandling, popupService, preferencesStorage, claimsService) {

        var vm = this;
        vm.countrySearch = "";
        vm.settings = {};
        vm.hostnamePrefixesAsText = "";

        // Functions
        vm.save = save;
        vm.validate = validate;
        vm.doCancel = doCancel;
        vm.openAcmeTos = openAcmeTos;
        vm.clearSearchTerm = clearSearchTerm;
        vm.filterCountrySearch = filterCountrySearch;
        vm.loadCountryCodes = loadCountryCodes;

        activate();

        ////////////////////////

        function activate() {
            vm.acmeTosTitle = $translate.instant("VIEW_TERMS_OF_SERVICE");
            $scope.$watch("settings.acmeSettings.provider", onAcmeProviderChanged);

            refresh();

            $scope.$on("masonry:loaded", function () { vm.pageForm.$setPristine(); });
        }

        async function refresh() {
            try {
                var promises = [
                    $http.get("~/api/v1/settings/sysadmin/global-mail").then(onGlobalMailSettingsLoaded, function () { }),
                    $http.get("~/api/v1/settings/domain/country-codes").then(onCountryCodesLoaded, errorHandling.report),
                    loadCountryCodes()
                ];

                $rootScope.spinner.show();
                await Promise.all(promises);
            }
            catch (err) {
                errorHandling.report(err);
            } finally {
                vm.pageForm.$setPristine();
                vm.pageForm.$setUntouched();
                vm.pageForm.$setSubmitted();
                $rootScope.spinner.hide();

            }

            function onCountryCodesLoaded(result) {
                vm.countries = [];
                angular.forEach(result.data.results, function (key) {
                    vm.countries.push({ key: key, translation: $translate.instant('COUNTRY_CODE_' + key) })
                });
            }

            function onGlobalMailSettingsLoaded(result) {
                $scope.settings = angular.copy(result.data.globalMailSettings);
                vm.settings = $scope.settings;
                vm.originalCertificatesPath = vm.settings.pathSettings.certificatesPath;

                //ACME
                vm.wasAcmeEnabledBefore = vm.settings.acmeSettings.isEnabled;
                vm.settings.acmeSettings.newCertStorePw = vm.settings.acmeSettings.isCertStorePwSet ? "************" : "";
                vm.hostnamePrefixesAsText = vm.settings.acmeSettings.hostnamePrefixes.sort().join("\n");
            }
        }

        function onAcmeProviderChanged(newValue, oldValue) {
            switch (newValue) {
                case 1:
                    let name = $translate.instant("LETS_ENCRYPT");
                    vm.acmeTosTitle = $translate.instant("VIEW_TERMS_OF_SERVICE_X", { x: name });
                    break;
                default:
                    vm.acmeTosTitle = $translate.instant("VIEW_TERMS_OF_SERVICE");
                    break;
            }
        }

        function clearSearchTerm() {
            vm.countrySearch = "";
        }

        function filterCountrySearch(item) {
            return item.translation.toLowerCase().startsWith(vm.countrySearch.toLowerCase()) || item.key.toLowerCase().startsWith(vm.countrySearch.toLowerCase());
        }

        function loadCountryCodes() {
            $http.get("~/api/v1/settings/domain/country-codes")
                .then(onCountryCodesLoaded, errorHandling.report);
            function onCountryCodesLoaded(result) {
                vm.countries = [];
                angular.forEach(result.data.results, function (key) {
                    vm.countries.push({ key: key, translation: $filter("translate")("COUNTRY_CODE_" + key) });
                });
            }
        }
        function doValidate(form) {
            if (form.$invalid) {
                form.$error.required.forEach((input) => {
                    input.$setTouched();
                })
            }
        }
        function validate(form) {
            $timeout(function () { doValidate(form); }, 100);

        }

        function openAcmeTos() {
            var url = stSiteRoot + 'acme-tos/' + vm.settings.acmeSettings.provider;
            window.open(url, "acme_tos");
        }

        function doCancel(ev, form) {
            if (!form.$dirty) return;
            $mdDialog
                .show($mdDialog.confirmRevertChanges().targetEvent(ev))
                .then(function () { refresh(); form.$setPristine(); }, function () { });
        }

        async function save(ev, form) {
            if (form.$invalid || form.$pending) {
                errorHandling.report("CORRECT_INVALID_FIELDS");
                return;
            }

            if (vm.originalCertificatesPath != vm.settings.pathSettings.certificatesPath) {
                try {
                    const confirm = $mdDialog.confirm({
                        title: $translate.instant('CONFIRMATION_REQUIRED'),
                        textContent: $translate.instant('SSL_TLS_CONFIRM_DIRECTORY_CHANGE'),
                        ok: $translate.instant('OK'),
                        cancel: $translate.instant('CANCEL'),
                    });
                    await $mdDialog.show(confirm);
                }
                catch (err) {
                    // Error means dialog was closed. Just exit this function
                    $scope.$applyAsync(() => vm.settings.pathSettings.certificatesPath = vm.originalCertificatesPath);
                    return;
                }
            }

            try {
                $rootScope.spinner.show();

                vm.settings.acmeSettings.hostnamePrefixes = vm.hostnamePrefixesAsText.split('\n');
                var params = JSON.stringify({
                    globalMailSettings: {
                        acmeSettings: vm.settings.acmeSettings,
                        pathSettings: { certificatesPath: vm.settings.pathSettings.certificatesPath },
                    }
                });
                await $http.post("~/api/v1/settings/sysadmin/global-mail", params);
                vm.originalCertificatesPath = vm.settings.pathSettings.certificatesPath;

                form.$setPristine();
                if (!vm.wasAcmeEnabledBefore && vm.settings.acmeSettings.isEnabled) {
                    var params = JSON.stringify({});
                    await $http.post("~/api/v1/settings/sysadmin/acme-certificates/refresh", params);
                    vm.wasAcmeEnabledBefore = true;
                }
                $rootScope.$broadcast('system-settings:changed');
                $rootScope.$broadcast("signalR.mailHub.client.acmeCertsChanged");
            } catch (err) {
                errorHandling.report(err);
            } finally {
                $rootScope.spinner.hide();
                refresh();
            }
        }
    }

})();

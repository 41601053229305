(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminSpoolManageQuaController", adminSpoolManageQuaController);

	function adminSpoolManageQuaController($scope, $filter, $mdDialog, $http, $rootScope, $localStorage, $translate, $compile,
		errorHandling, coreDataSpool, coreData, coreDataSysAdminSettings, successHandling, NgTableParams, gridCheckboxes, popupService, coreDataFileStorage, $timeout) {
		var vm = this;
		$scope.spoolCore = coreDataSpool;
		$scope.gridColumns = [
			{ sortField: 'fileName', translate: 'FILENAME' },
			{ sortField: 'creationDate', translate: 'DATE' },
			{ sortField: 'from', translate: 'SENDER' },
			{ sortField: 'deliveredratio', translate: 'RECIPIENTS' },
			{ sortField: 'size', translate: 'SIZE' },
			{ sortField: 'timeTranslate', translate: 'SPOOL_TIME_IN_SPOOL' },
			{ sortField: 'timeuntilremoval', translate: 'SPOOL_TIME_OF_REMOVAL' }
		];
		$scope.searchText = '';
		vm.queryUrl = "~/api/v1/settings/sysadmin/spool-messages";
		vm.searchParams = { spoolInput: [{ startIndex: 0, count: 0, sortType: null, search: '', ascending: false, filter: 'quarantine_spamout' }] };
		vm.selectedSection = $localStorage.spoolSection ? $localStorage.spoolSection : 'SPOOL_SECTIONS_SPAM_QUARANTINE';
		vm.tableParams = new NgTableParams(
			{
				sorting: { 2: 'asc' },
				count: 100
			}, {
				getData: queryData,
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init("fileName");
		vm.checkboxes.table = vm.tableParams;
		vm.spoolStatus = coreDataSpool.spoolStatus;
		vm.recipientStatus = coreDataSpool.recipientStatus;
		vm.sortFieldTypes = coreDataSpool.sortFieldTypes;

		// Functions
		vm.resendMessages = resendMessages;
		vm.deleteMessages = deleteMessages;
		vm.deleteAllMessages = deleteAllMessages;
		vm.viewMessage = viewMessage;
		vm.moveMessages = moveMessages;
		vm.downloadMessages = downloadMessages;
		vm.Refresh = Refresh;
		vm.searchItems = searchItems;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.copyToClipboard = copyToClipboard;

		activate();

		/////////////////////

		function activate() {
			changeCurrentSection(vm.selectedSection);
		}
	
		function changeCurrentSection(section) {
			vm.selectedSection = section;
			coreDataSpool.changeSection(section);
			vm.queryUrl = "~/api/v1/settings/sysadmin/spool-messages";
			switch (section) {
				default:
				case 'SPOOL_SECTIONS_SPAM_QUARANTINE':
					vm.searchParams = { spoolInput: [{ startIndex: 0, count: 0, sortType: null, search: '', ascending: false, filter: 'quarantine_spamout' }] };
					break;
			}
			vm.checkboxes.reset();
			vm.tableParams.reload();
			$scope.$emit('reloadSpool');
		}

		function resendMessages() {
			var toResend = [];
			for (var key in vm.checkboxes.items) {
				if (vm.checkboxes.items[key]) {
					toResend = toResend.concat($.grep(vm.searchResults, function (r) { return r.fileName === key }));
				}
			}
			if (toResend.length === 0) {
				errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED"); return;
			} else if (toResend.length == 1) {
				doResendMessages();
			} else {
				var confirm = $mdDialog.confirmDeletion()
					.textContent($filter('translate')('CONFIRMATIONS_MULTI_GENERIC', { count: toResend.length }))
					.ok($filter('translate')('OK'))
					.title($filter('translate')('CONFIRMATION_REQUIRED'));
				$mdDialog.show(confirm).then(function () { doResendMessages(); }, function () {});
			}

			function doResendMessages() {
				coreDataSpool
					.resendMessages(toResend)
					.then(deleteSuccess, errorHandling.report);
			}
		}

		function deleteMessages(ev) {
			var toDelete = [];
			for (var key in vm.checkboxes.items) {
				if (vm.checkboxes.items[key]) {
					toDelete = toDelete.concat($.grep(vm.searchResults, function (r) { return r.fileName === key; }));
				}
			}
			if (toDelete.length === 0) {
				errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED"); return;
			}

			var confirm = $mdDialog
				.confirmDeletion()
				.textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: toDelete.length }))
				.targetEvent(ev);

			$mdDialog
				.show(confirm)
				.then(function () {
					coreDataSpool
						.deleteMessages(toDelete)
						.then(deleteSuccess, errorHandling.report);
				}, function () { });
		}

		function deleteAllMessages(ev) {
			var confirm = $mdDialog
				.confirmDeletion()
				.textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS_ALL"))
				.targetEvent(ev);

			$mdDialog
				.show(confirm)
				.then(function() {
					coreDataSpool
						.deleteAllQuarantineMessagesByType(2)
						.then(deleteSuccess, errorHandling.report);
				},
				function() {});
		}

		function deleteSuccess() {
			$scope.$emit("reloadSpool");
			vm.checkboxes.reset();
			vm.tableParams.reload();
			successHandling.report();
		}

		function viewMessage(ev, row) {
			var windowHref = coreDataSpool.getViewMessage(coreData.baseUrl, row);
			window.open(windowHref, $filter('translate')('SPOOL_VIEW_RECIPIENTS'), 'resizable=1, ' + popupService.dimensions.email);
		}

		function moveMessages(ev, rows) {
			var toMove = [];
			for (var key in vm.checkboxes.items) {
				if (vm.checkboxes.items[key]) {
					toMove = toMove.concat($.grep(vm.searchResults, function (r) { return r.fileName === key; }));
				}
			}
			if (toMove.length === 0) {
				errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED"); return;
			}

			var folderName = "MovedSpoolItems";
			if (window.stOS === 'W')
				folderName += "\\";
			else
				folderName += "/";
			
			$mdDialog.show({
				locals: {
					spoolPath: coreDataSysAdminSettings.settings.globalMailSettings.pathSettings.spoolPath + folderName,
					address: '',
					type: 6,
					count: toMove.length,
					editAddress: false
				},
				controller: "spoolMoveConfirmController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/manage/modals/admin-spool-move-confirm.dlg.html",
				targetEvent: ev
			})
				.then(function (modalSuccess) {
					var moveInput = [];
					for (var i = 0; i < toMove.length; ++i) {
						moveInput.push({ fileName: toMove[i].fileName.replace('.eml', ''), spoolName: toMove[i].spoolName });
					}
					var params = JSON.stringify({ spoolInput: moveInput, moveLocation: modalSuccess.location });
					$http.post('~/api/v1/settings/sysadmin/move-selected-spool-messages/', params)
						.then(function (success) {
							$scope.$emit('reloadSpool');
							vm.checkboxes.reset();
							vm.tableParams.reload();
							successHandling.report();
						}, errorHandling.report);
				}, function (cancel) {
					return;
				});
		}
		function downloadMessages(ev, rows) {
			var toDownload = [];
			for (var key in vm.checkboxes.items) {
				if (vm.checkboxes.items[key]) {
					toDownload = toDownload.concat($.grep(vm.searchResults, function (r) { return r.fileName === key }));
				}
			}
			var input = [];
			toDownload.forEach(x => input.push({ fileName: x.fileName, spoolName: x.spoolName }));

			if (input.length === 0) {
				errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED"); return;
			}
			var fileName = $filter("translate")("SPOOL_QUARANTINED") + "-";
			fileName += input.length === 1 ? input[0].fileName : $filter("translate")("FILENAME_MESSAGES_WITH_COUNT", { count: input.length }) + ".zip";
			var progressTimeout = $timeout(function () {
					var progress = $mdDialog.stProgressBar()
						.title($translate.instant("DOWNLOADING"))
						.barText("")
						.close($translate.instant("DOWNLOAD_IN_BACKGROUND"))
						.showCancel(false)
						.enableButtons(true)
						.autoClose(true)
						.percentProgress(-1)
						.guid("eml-download")
						.progressListener("eml-download.finished");
					$mdDialog.show(progress);
				},
				1000);

			var params = JSON.stringify({ spoolInput: input });
			coreDataFileStorage.downloadFile("~/api/v1/settings/sysadmin/spool-messages-export/", fileName, params)
				.then(function () {
					},
					function (failure) {
						errorHandling.report(failure);
					}).finally(function () {
					$rootScope.$broadcast("eml-download.finished", { guid: "eml-download", current: -1 });
					if (progressTimeout) $timeout.cancel(progressTimeout);
				});

		}

		function Refresh() {
			$scope.$emit('reloadSpool');
			vm.checkboxes.reset();
			vm.tableParams
				.reload()
				.then(function () { }, function () { })
				.finally(function () { successHandling.report("ACTION_SUCCEEDED"); });
		}

		function searchItems() {
			vm.searchParams.spoolInput[0].search = vm.searchText;
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

		function queryData(params) {
			vm.searchParams.spoolInput[0].startIndex = (params.page() - 1) * params.count();
			vm.searchParams.spoolInput[0].count = params.count();
			vm.searchParams.spoolInput[0].sortType = null;
			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				vm.searchParams.spoolInput[0].sortType = k;
				vm.searchParams.spoolInput[0].ascending = params.sorting()[k] !== 'desc';
				break;
			}

			$rootScope.spinner.show();
			return $http
				.post(vm.queryUrl, JSON.stringify(vm.searchParams))
				.then(onSearchSuccess)
				.finally($rootScope.spinner.hide);

			function onSearchSuccess(result) {
				vm.searchResults = result.data.spoolListData[0].messages;
				vm.searchResultCount = result.data.spoolListData[0].messageCount;
				angular.forEach(vm.searchResults, function (message) {
					var time = moment.duration(message.timeinspool);
					message.timeHours = Math.floor(time.asHours());
					message.timeMinutes = Math.floor(time.asMinutes() - (message.timeHours * 60));
					message.timeSeconds = Math.floor(time.asSeconds() - ((message.timeMinutes + message.timeHours * 60) * 60));
					message.timeTranslate = $filter('translate')('SPOOL_TIME', { hours: message.timeHours, minutes: message.timeMinutes, seconds: message.timeSeconds });
					if (message.status === 7) {
						var quTime = moment.duration(message.timeinquarantine);
						message.timeQuHours = Math.floor(quTime.asHours());
						message.timeQuMinutes = Math.floor(quTime.asMinutes() - (message.timeQuHours * 60));
						message.timeQuSeconds = Math.floor(quTime.asSeconds() - ((message.timeQuMinutes + message.timeQuHours * 60) * 60));
						message.timeQuTranslate = $filter('translate')('SPOOL_TIME', { hours: message.timeQuHours, minutes: message.timeQuMinutes, seconds: message.timeQuSeconds });
						message.timeuntilremoval = new Date(message.timeuntilremoval);
					}
					if (message.status === 5) {
						if (message.pending === 0) {
							message.statusStr = 'DELIVERED';
						} else {
							message.statusStr = message.isThrottled ? 'THROTTLED' : 'WAITING_TO_DELIVER';
						}
					} else {
						message.statusStr = vm.spoolStatus[message.status];
					}
					message.statusStr = $filter('translate')(message.statusStr);
					message.creationDate = new Date(message.creationDate);
					message.fileName = message.fileName + ".eml";
				});
				params.total(vm.searchResultCount);
				
				return vm.searchResults;
			}
		}

		window.refreshSpool = function () {
			vm.checkboxes.reset();
			vm.tableParams.reload();
			$scope.$emit('reloadSpool');
		};

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.fileName; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.fileName];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'resendMessages', click: resendMessages, translateKey: 'RESEND' },
				{ key: 'moveMessages', click: moveMessages, params: $scope.dropdownEvent, translateKey: 'SPOOL_MOVE_MESSAGES' },
				{ key: 'downloadMessages', click: downloadMessages,  params: $scope.dropdownEvent, translateKey: "DOWNLOAD_EML" },
				{ key: 'deleteMessages', click: deleteMessages, params: $scope.dropdownEvent, translateKey: 'DELETE' },
				{ divider: true },
				{ key: 'copyPath', click: copyToClipboard, params: { ev: $scope.dropdownEvent, item: item.spoolName + "/" + item.fileName }, translateKey: "COPY_PATH" },
				{ key: 'copySender', click: copyToClipboard, params: { ev: $scope.dropdownEvent, item: item.from }, translateKey: "COPY_SENDER" },
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function copyToClipboard(params) {
			if (!params || !params.item)
				return;

			var textArea = document.createElement("textarea");
			textArea.value = params.item;
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
			document.execCommand("copy");
			document.body.removeChild(textArea);
			successHandling.report("COPIED_TO_CLIPBOARD");
		}
	}
})();

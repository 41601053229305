(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("chatSectionController", chatSectionController);

	function chatSectionController($rootScope, $scope, $timeout, $interval, $http, $state, errorHandling, $filter, coreData, coreDataFileStorage,
		simpleXmppService, authStorage, preferencesStorage, crc32Service, userDataService, $log, claimsService, $transitions, treeState) {

		// Data
		var vm = this;
		vm.isVisible = false;
		vm.isInitialized = false;
		vm.userDataService = userDataService;
		vm.isVisible = false;
		vm.treeState = treeState;

		activate();

		function activate() {
			simpleXmppService.init();
		}

		// Startup
		//loadFeatures();

		//// Implementation
		//function loadFeatures() {
		//	userDataService
		//		.init()
		//		.then(function (success) {
		//			vm.isVisible = userDataService.user.settings.features && userDataService.user.settings.features.enableChat;
		//			if (vm.isVisible) {
		//				connectXmpp();
		//			}
		//		});
		//}

		//function connectXmpp() {
		//	vm.isInitialized = true;
		//	if (simpleXmppService.parameters.status == 'offline') { vm.status = 'offline'; return; }
		//	simpleXmppService.boshUrl = stSiteRoot + "httpbind";
		//	simpleXmppService.authenticateOAuth2(claimsService.getEmailAddress(), authStorage.getToken());
		//	$scope.$applyAsync();
		//	$scope.$on("treeState:stateChange", function (event, data) {
		//		$scope.treeExpanded = data.expanded;
		//		$timeout(function () { $(window).trigger('resize'); }, 250);
		//	});
		//}

		//function toggleTree() {
		//	vm.treeExpanded = !vm.treeExpanded;
		//}

		function setChatStatus(newStatus) {
			if (!isOnline() && newStatus != 'offline')
			{ vm.status = 'connecting'; simpleXmppService.parameters.status = newStatus; connectXmpp(); vm.contactCategories = []; }
			else
				simpleXmppService.setStatus(newStatus);
		}

		function isOnline(status) {
			if (status) return status === 'available' || status === 'away' || status === 'dnd';
			return vm.status === 'available' || vm.status === 'away' || vm.status === 'dnd';
		}
	}
})();
(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("adminSettingsGeneralSettingsController", adminSettingsGeneralSettingsController);

    function adminSettingsGeneralSettingsController($rootScope, $scope, $filter, $mdDialog, $q, $http, $state, $translate,
        coreLicensing, errorHandling, successHandling, popupService, preferencesStorage, claimsService) {
        var vm = this;
        $scope.uploaderData = JSON.stringify({ domain: null, guid: createGuid() });

        var num = 1000;
        vm.imagePendingSave = false;
        vm.pendingRemoveImage = false;
        vm.previewingLogin = false;
        vm.uploaderFunctionsImage = false;
        vm.logoAction = "";
        vm.loginPreviewGuid = "";
        vm.countrySearch = "";

        //// Functions
        vm.save = save;
        vm.doCancel = doCancel;
        vm.autoCleanNewRule = autoCleanNewRule;
        vm.autoCleanEditRule = autoCleanEditRule;
        vm.autoCleanDeleteItem = autoCleanDeleteItem;
        vm.autoCleanGetCleanBottomString = autoCleanGetCleanBottomString;
        vm.reindexDomainArchive = reindexDomainArchive;
        vm.editAchiveRule = editAchiveRule;
        vm.disableArchivingRule = disableArchivingRule;
        // Blocked Country functions
        vm.clearSearchTerm = clearSearchTerm;
        vm.deleteCountryFromList = deleteCountryFromList;
        vm.countryListChanged = countryListChanged;
        vm.autoCleanAllowDomainOverrideChanged = autoCleanAllowDomainOverrideChanged;

        // Uploader
        $scope.adjustLogoHover = adjustLogoHover;
        vm.imagePreviewImage = imagePreviewImage;
        vm.removePicture = removePicture;
        vm.uploaderInitImage = uploaderInitImage;
        $scope.uploadFile = uploadFile;

        // Open Modal
        vm.openFileStorageExtensionBlacklist = openFileStorageExtensionBlacklist;
        vm.openLoginPageHtml = openLoginPageHtml;
        vm.openLoginPreview = openLoginPreview;
        vm.openWebmailExtensionBlacklist = openWebmailExtensionBlacklist;
        vm.openOutgoingExtensionBlacklist = openOutgoingExtensionBlacklist;
        vm.openFooterBody = openFooterBody;

        // On Changed
        vm.customHelpTextChanged = customHelpTextChanged;
        vm.customHelpUrlChanged = customHelpUrlChanged;
        vm.customHelpEnableChanged = customHelpEnableChanged;
        vm.customLoginTextChanged = customLoginTextChanged;
        vm.customLoginPageHtmlEnabledChanged = customLoginPageHtmlEnabledChanged;
        vm.loginBackgroundColorChanged = loginBackgroundColorChanged;
        vm.loginBackgroundImagePathChanged = loginBackgroundImagePathChanged;
        vm.loginBackgroundOptionChanged = loginBackgroundOptionChanged;
        vm.clearSearchTerm = clearSearchTerm;
        vm.filterCountrySearch = filterCountrySearch;
        vm.loadCountryCodes = loadCountryCodes;

        activate();

        /////////

        function activate() {
            recalculateLicense();
            coreLicensing.watchForChanges($scope, recalculateLicense);
            refresh();

            $scope.$on("$destroy", function () {
                closePreviewWindow();
                window.removeEventListener("beforeunload", closePreviewWindow);
            });

            $scope.$on("masonry:loaded", function () { vm.pageForm.$setPristine(); });

            window.addEventListener("beforeunload", closePreviewWindow);

            function closePreviewWindow() {
                if (vm.previewWindow)
                    vm.previewWindow.close();
            }

            function recalculateLicense() {
                vm.edition = coreLicensing.edition;
                vm.hasEnterpriseFunctionality = coreLicensing.hasEnterpriseFunctionality;
            }
        }

        function clearSearchTerm() {
            vm.countrySearch = "";
        }

        function filterCountrySearch(item) {
            return item.translation.toLowerCase().startsWith(vm.countrySearch.toLowerCase()) || item.key.toLowerCase().startsWith(vm.countrySearch.toLowerCase());
        }

        function loadCountryCodes() {
            $http.get("~/api/v1/settings/domain/country-codes")
                .then(onCountryCodesLoaded, errorHandling.report);
            function onCountryCodesLoaded(result) {
                vm.countries = [];
                angular.forEach(result.data.results, function (key) {
                    vm.countries.push({ key: key, translation: $filter("translate")("COUNTRY_CODE_" + key) });
                });
            }
        }

        function refresh() {
            var promises = [
                $http.get("~/api/v1/settings/sysadmin/global-mail").then(onGlobalMailSettingsLoaded, function () { }),
                $http.get("~/api/v1/settings/sysadmin/antivirus/settings").then(onVirusSettingsLoaded, function () { }),
                $http.get("~/api/v1/companyinfo").then(onCompanyInfoLoaded, function () { }),
                $http.get('~/api/v1/settings/auto-clean').then(onAutoCleanLoadCompleted, function () { }),
                $http.get("~/api/v1/settings/domain/country-codes").then(onCountryCodesLoaded, errorHandling.report),
                loadCountryCodes()
            ];
            if (vm.hasEnterpriseFunctionality) {
                promises.push(
                    $http.get("~/api/v1/settings/sysadmin/domain-archive-rules").then(onArchiveRulesLoaded, function () { })
                );
            }
            $rootScope.spinner.show();
            $q
                .all(promises)
                .finally(function () {
                    vm.pageForm.$setPristine();
                    vm.pageForm.$setUntouched();
                    vm.pageForm.$setSubmitted();

                    $rootScope.spinner.hide();

                    vm.logoAction = "revert";
                    if (vm.previewingLogin)
                        setLocalStorageForPreview();
                });
            refreshImages();

            function onVirusSettingsLoaded(result) {
                vm.antiVirusSettings = result.data.settings;
                if (vm.antiVirusSettings.quarantineDirectory === "VIRUS_QUARANTINE") {
                    vm.antiVirusSettings.quarantineDirectory = "";
                }
            }

            function onCountryCodesLoaded(result) {
                vm.countries = [];
                angular.forEach(result.data.results, function (key) {
                    vm.countries.push({ key: key, translation: $translate.instant('COUNTRY_CODE_' + key) })
                });
            }
            function onGlobalMailSettingsLoaded(result) {
                $scope.settings = angular.copy(result.data.globalMailSettings);
                vm.settings = $scope.settings;

                vm.fileStorageExtensionBlacklist = $scope.settings.fileTypeRestriction.blackList || [];
                vm.webmailExtensionBlacklist = $scope.settings.webmailFileTypeRestriction.blackList || [];
                vm.outgoingExtensionBlacklist = $scope.settings.outgoingFileTypeRestriction.blackList || [];
                vm.autoCleanSettings = $scope.settings.autoCleanSettings;
                if (vm.autoCleanSettings.autoCleanNotificationGlobalSettings == 0)
                    vm.autoCleanSettings.autoCleanNotificationGlobalSettings = 30;

                // Paths
                vm.logPath = $scope.settings.pathSettings.logPath;

                // File Storage
                vm.maxFileSizeEnabled = $scope.settings.fileStorageSettings.fileRestriction.maxSizeEnabled;
                vm.maxFileSize = $scope.settings.fileStorageSettings.fileRestriction.maxSizeInKb;
                vm.urlOverrideEnabled = $scope.settings.fileStorageSettings.storageUrl.urlOverrideEnabled;
                vm.urlOverride = $scope.settings.fileStorageSettings.storageUrl.urlOverride;

                // Server info
                vm.hostname = $scope.settings.serverSettings.hostName;
                vm.primaryDnsIp = $scope.settings.serverSettings.dns1;
                vm.secondaryDnsIp = $scope.settings.serverSettings.dns2;
                vm.mailAvatarPrivacy = $scope.settings.serverSettings.mailAvatarPrivacy;
                vm.logoutUrl = $scope.settings.sysAdminSettings.serverUrl;
                vm.allowDomainAdminsToOverrideLogoutUrl = $scope.settings.sysAdminSettings.allowDomainsToOverrideUrl;
                vm.siteWideSSLRequired = $scope.settings.sysAdminSettings.siteWideSSLRequired;
                vm.allowDomainAdminsToOverrideSSLRequirement = $scope.settings.sysAdminSettings.allowDomainAdminsToOverrideSSLRequirement;

                // Spool
                vm.spoolPath = $scope.settings.pathSettings.spoolPath;
                vm.subSpools = $scope.settings.deliverySettings.spoolSubSpools;
                vm.deliveryDelay = $scope.settings.deliverySettings.relayInterval / 1000;
                vm.retryIntervals = $scope.settings.deliverySettings.relayRetryTimes.replace(/\s+/g, '').split(',').join(', ');
                vm.bounceDnsErrors = $scope.settings.deliverySettings.bounceDnsErrorsAfterXAttempts;
                vm.notifySendersOfDelay = $scope.settings.deliverySettings.notifyOfDelayAfterXAttempts;
                vm.maxLocalDeliveryThreads = $scope.settings.deliverySettings.maxLocalDeliveryThreads;

                // Reports
                vm.deleteServerStats = $scope.settings.statCleanerSettings.statCleanerAutoDeleteServerStatsMonths;
                vm.deleteDomainStats = $scope.settings.statCleanerSettings.statCleanerAutoDeleteDomainStatsMonths;
                vm.deleteUserStats = $scope.settings.statCleanerSettings.statCleanerAutoDeleteUserStatsMonths;
                vm.deleteServerStatsEnabled = $scope.settings.statCleanerSettings.statCleanerAutoDeleteServerStats;
                vm.deleteDomainStatsEnabled = $scope.settings.statCleanerSettings.statCleanerAutoDeleteDomainStats;
                vm.deleteUserStatsEnabled = $scope.settings.statCleanerSettings.statCleanerAutoDeleteUserStats;

                // Footer
                vm.footerEnabled = $scope.settings.footerSettings.enabled;
                vm.footerApplyToMailingLists = $scope.settings.footerSettings.applyToMailingLists;
                vm.footerHtml = $scope.settings.footerSettings.footerText;
                vm.footerDomainOverride = $scope.settings.footerSettings.allowOverride;

                // Blocked Country Settings  
                vm.blockedCountrySettings = $scope.settings.blockedCountrySettings;

                countryListChanged();
            }

            function onCompanyInfoLoaded(result) {
                $scope.companyInfo = angular.copy(result.data.companyInfo);
                vm.customHelpLinkEnabled = $scope.companyInfo.customHelpLinkEnabled;
                vm.customHelpUrl = $scope.companyInfo.customHelpLink;
                if (vm.customHelpUrl && (vm.customHelpUrl.toLowerCase() === "http://" || vm.customHelpUrl.toLowerCase() === "https://")) {
                    vm.customHelpUrl = "";
                }
                vm.customHelpText = $scope.companyInfo.customHelpText;
                vm.customTitleText = $scope.companyInfo.customTitleText;
                vm.customLoginText = $scope.companyInfo.customLoginTextEnabled ? $scope.companyInfo.customLoginText : "";
                vm.customLoginTextEnabled = $scope.companyInfo.customLoginTextEnabled;
                vm.customLoginPageHtmlEnabled = $scope.companyInfo.showLoginAreaHTML;
                vm.loginPageHtml = $scope.companyInfo.htmlDisplayBadge;
                vm.loginBackgroundOption = $scope.companyInfo.loginBackgroundOption;
                vm.loginBackgroundImagePath = $scope.companyInfo.loginBackgroundImagePath;
                vm.loginBackgroundColor = $scope.companyInfo.loginBackgroundColor;

                $rootScope.$broadcast('pageTitleUpdated', vm.customTitleText);
            }

            function onAutoCleanLoadCompleted(result) {
                vm.autoCleanFolders = [{
                    name: 'deleted items', folderName: 'deleted items', path: 'deleted items',
                    translatedPath: $translate.instant("DELETED_ITEMS")
                }, {
                    name: 'inbox', folderName: 'inbox', path: 'inbox',
                    translatedPath: $translate.instant("INBOX")
                }, {
                    name: 'junk e-mail', folderName: 'junk e-mail', path: 'junk e-mail',
                    translatedPath: $translate.instant("JUNK_EMAIL")
                }, {
                    name: 'sent items', folderName: 'sent items', path: 'sent items',
                    translatedPath: $translate.instant("SENT_ITEMS")
                }
                ];

                vm.autoCleanSystemRules = result.data.systemRules || [];
                for (var i = 0; i < vm.autoCleanSystemRules.length; ++i) {
                    vm.autoCleanSystemRules[i].translatedPath = vm.autoCleanFolders.find(acf => acf.name === vm.autoCleanSystemRules[i].folderName)["translatedPath"] || vm.autoCleanSystemRules[i].folderName;
                }
            }

            function onWebRtcConfigLoadCompleted(result) {
                vm.webRtcServers = result.data.stunTurnServers;
            }
            function onArchiveRulesLoaded(result) {
                var archiveRules = result.data.rules || [];
                vm.systemArchiveRule = archiveRules.find(rule => rule.isDefault);
                if (!vm.systemArchiveRule)
                    return;
                vm.archiveRule = {
                    type: vm.systemArchiveRule.disabled || (vm.systemArchiveRule.outgoing && vm.systemArchiveRule.incoming)
                        ? "1"
                        : vm.systemArchiveRule.incoming ? "2" : vm.systemArchiveRule.outgoing ? "3" : "1",
                    archivePath: !vm.systemArchiveRule.disabled && vm.systemArchiveRule.archivePath && vm.systemArchiveRule.archivePath.length > 0
                        ? vm.systemArchiveRule.archivePath
                        : $scope.settings.pathSettings.archivePath,
                    yearsToKeep: !vm.systemArchiveRule.disabled && typeof vm.systemArchiveRule.monthsToKeep === "number"
                        ? vm.systemArchiveRule.monthsToKeep > 0 ? Math.ceil(vm.systemArchiveRule.monthsToKeep / 12) : 0
                        : 5,
                    domain: vm.systemArchiveRule.domain,
                    isNew: vm.systemArchiveRule.archivePath.length > 0,
                    disabled: vm.systemArchiveRule.disabled,
                    archiveName: $translate.instant("ALL_DOMAINS")
                }
            }

        }

        function doCancel(ev, form) {
            if (!form.$dirty) return;
            $mdDialog
                .show($mdDialog.confirmRevertChanges().targetEvent(ev))
                .then(function () { refresh(); form.$setPristine(); }, function () { });
        }

        function save(ev, form) {
            if (form.$invalid || form.$pending) {
                errorHandling.report("CORRECT_INVALID_FIELDS");
                return;
            }

            $rootScope.spinner.show();

            saveGlobalMailSettings()
                //.then(saveWebRtcSettings)
                .then(saveCompanyInfo)
                .then(saveSpoolExe)
                .then(saveFolderAutoClean)
                .then(saveImages)
                .then(onSaveSuccess)
                .catch(errorHandling.report)
                .finally(function () {
                    $rootScope.spinner.hide();
                    refresh();
                });

            //function saveWebRtcSettings() {
            //	var params = {
            //		stunTurnServers: vm.webRtcServers
            //	};
            //	return $http.post('~/api/v1/settings/domain/webrtc-config', JSON.stringify(params));
            //}

            function saveFolderAutoClean() {
                var params = {
                    allowAutoCleanInbox: vm.autoCleanSettings.autoCleanAllowInbox,
                    allowDomainOverride: vm.autoCleanSettings.autoCleanAllowDomainOverride,
                    systemRules: vm.autoCleanSystemRules
                };
                return $http.post('~/api/v1/settings/auto-clean-system', JSON.stringify(params));
            }

            function saveSpoolExe() {
                var params = JSON.stringify({
                    enableSpoolCommandLine: vm.antiVirusSettings.enableSpoolCommandLine,
                    spoolCommandLineFile: vm.antiVirusSettings.spoolCommandLineFile,
                    spoolCommandLineTimeout: vm.antiVirusSettings.spoolCommandLineTimeout,
                    quarantineDirectory: vm.antiVirusSettings.quarantineDirectory
                });
                return $http.post("~/api/v1/settings/sysadmin/antivirus/settings", params);
            }

            function saveImages() {

                if (vm.imagePendingSave) {
                    return vm.uploaderFunctionsImage.startUpload();
                }
                else if (vm.pendingRemoveImage) {
                    return $http.post("~/api/v1/companyinfo/logo-delete");
                }
                var defer = $q.defer();
                defer.resolve();
                return defer.promise;
            }

            function saveGlobalMailSettings() {
                if (vm.blockedCountrySettings.type === 0)
                    vm.blockedCountrySettings.countries = [];
                vm.blockedCountrySettings.countries =
                    vm.blockedCountrySettings.countries.filter(x => x !== "");
                var params = JSON.stringify({
                    globalMailSettings: {
                        serverSettings: {
                            hostName: vm.hostname,
                            dns1: vm.primaryDnsIp,
                            dns2: vm.secondaryDnsIp,
                            mailAvatarPrivacy: vm.mailAvatarPrivacy,
                        },
                        sysAdminSettings: {
                            serverUrl: vm.logoutUrl,
                            allowDomainsToOverrideUrl: vm.allowDomainAdminsToOverrideLogoutUrl,
                            siteWideSSLRequired: vm.siteWideSSLRequired,
                            allowDomainAdminsToOverrideSSLRequirement: vm.allowDomainAdminsToOverrideSSLRequirement
                        },
                        pathSettings: {
                            logPath: vm.logPath,
                            spoolPath: vm.spoolPath
                        },

                        deliverySettings: {
                            spoolSubSpools: vm.subSpools,
                            relayInterval: vm.deliveryDelay * 1000,
                            relayRetryTimes: vm.retryIntervals,
                            bounceDnsErrorsAfterXAttempts: vm.bounceDnsErrors,
                            notifyOfDelayAfterXAttempts: vm.notifySendersOfDelay,
                            maxLocalDeliveryThreads: vm.maxLocalDeliveryThreads
                        },
                        statCleanerSettings: {
                            statCleanerAutoDeleteServerStatsMonths: vm.deleteServerStats,
                            statCleanerAutoDeleteDomainStatsMonths: vm.deleteDomainStats,
                            statCleanerAutoDeleteUserStatsMonths: vm.deleteUserStats,
                            statCleanerAutoDeleteServerStats: vm.deleteServerStatsEnabled,
                            statCleanerAutoDeleteDomainStats: vm.deleteDomainStatsEnabled,
                            statCleanerAutoDeleteUserStats: vm.deleteUserStatsEnabled
                        },
                        fileStorageSettings: {
                            fileRestriction: {
                                maxSizeEnabled: vm.maxFileSizeEnabled,
                                maxSizeInKb: vm.maxFileSize
                            },
                            storageUrl: {
                                urlOverrideEnabled: vm.urlOverrideEnabled,
                                urlOverride: vm.urlOverride
                            }
                        },
                        footerSettings: {
                            enabled: vm.footerEnabled,
                            allowOverride: vm.footerDomainOverride,
                            applyToMailingLists: vm.footerApplyToMailingLists,
                            footerText: vm.footerHtml
                        },
                        webmailFileTypeRestriction: {
                            blackList: vm.webmailExtensionBlacklist
                        },
                        outgoingFileTypeRestriction: {
                            blackList: vm.outgoingExtensionBlacklist
                        },
                        fileTypeRestriction: {
                            blackList: vm.fileStorageExtensionBlacklist
                        },
                        autoCleanSettings: vm.autoCleanSettings,
                        blockedCountrySettings: vm.blockedCountrySettings,
                        externalSendersSys: vm.settings.externalSendersSys
                    }
                });
                return $http.post("~/api/v1/settings/sysadmin/global-mail", params);
            }

            function saveCompanyInfo() {
                var params = {
                    companyInfo: {
                        customHelpLink: vm.customHelpUrl,
                        customHelpLinkEnabled: vm.customHelpLinkEnabled,
                        customHelpText: vm.customHelpText,
                        customLoginText: vm.customLoginText,
                        customLoginTextEnabled: vm.customLoginText && vm.customLoginText.length > 0,
                        customTitleText: vm.customTitleText,
                        showLoginAreaHTML: vm.customLoginPageHtmlEnabled,
                        htmlDisplayBadge: vm.loginPageHtml,
                        loginBackgroundOption: vm.loginBackgroundOption,
                        loginBackgroundImagePath: vm.loginBackgroundImagePath,
                        loginBackgroundColor: vm.loginBackgroundColor,
                    }
                };
                return $http.post("~/api/v1/companyinfo", params);
            }

            function onSaveSuccess() {
                form.$setPristine();
                $rootScope.$broadcast('system-settings:changed');
                vm.logoAction = "replace";
                if (vm.previewingLogin)
                    setLocalStorageForPreview();
            }
        }

        //// Open Modal
        function openFileStorageExtensionBlacklist(event, form) {
            $mdDialog.show({
                locals: { extensions: angular.copy(vm.fileStorageExtensionBlacklist), title: "EXTENSION_BLACKLIST_FOR_UPLOADS", isReadOnly: false },
                controller: "extensionBlacklistDialogController",
                controllerAs: "ctrl",
                templateUrl: "app/settings/shared/extension-blacklist.dlg.html",
                targetEvent: event
            })
                .then(function (modalSuccess) {
                    vm.fileStorageExtensionBlacklist = modalSuccess.extensions;
                    form.$setDirty();
                }, function () { });
        }

        function openLoginPageHtml(ev, form) {
            $mdDialog.show({
                locals: { body: vm.loginPageHtml },
                controller: "domainEditLoginHtmlController",
                controllerAs: "ctrl",
                templateUrl: "app/settings/domain-settings/general-settings/login-html.dlg.html",
                targetEvent: ev
            })
                .then(function (modalSuccess) {
                    if (vm.loginPageHtml === modalSuccess)
                        return;
                    vm.isLoginDisplayChanged = true;
                    vm.loginPageHtml = modalSuccess;
                    form.$setDirty();
                    preferencesStorage.setLocalParam("loginPreviewChanges", { customHtmlBlock: vm.loginPageHtml });
                });
        }

        function openLoginPreview() {
            if (vm.logoChanged) {
                uploadTempFile()
                    .then(openPreview, errorHandling.report);
            }
            else
                openPreview();

            function openPreview() {
                vm.previewingLogin = true;
                setLocalStorageForPreview();
                vm.previewWindow = window.open(window.location.href.replace('/sysadmin/settings/general-settings', '/popout/login-preview'),
                    "login-preview", "resizable=1, " + popupService.dimensions.loginPreview);
            }
        }

        function openWebmailExtensionBlacklist(event, form) {
            $mdDialog.show({
                locals: { extensions: angular.copy(vm.webmailExtensionBlacklist), title: "INBOUND_EXTENSION_BLACKLIST", isReadOnly: false },
                controller: "extensionBlacklistDialogController",
                controllerAs: "ctrl",
                templateUrl: "app/settings/shared/extension-blacklist.dlg.html",
                targetEvent: event
            })
                .then(function (modalSuccess) {
                    vm.webmailExtensionBlacklist = modalSuccess.extensions;
                    form.$setDirty();
                }, function () { });
        }

        function openOutgoingExtensionBlacklist(event, form) {
            $mdDialog.show({
                locals: { extensions: angular.copy(vm.outgoingExtensionBlacklist), title: "OUTBOUND_EXTENSION_BLACKLIST", isReadOnly: false },
                controller: "extensionBlacklistDialogController",
                controllerAs: "ctrl",
                templateUrl: "app/settings/shared/extension-blacklist.dlg.html",
                targetEvent: event
            })
                .then(function (modalSuccess) {
                    vm.outgoingExtensionBlacklist = modalSuccess.extensions;
                    form.$setDirty();
                }, function () { });
        }

        function openFooterBody(ev, form) {
            $mdDialog.show({
                locals: {
                    footerText: vm.footerHtml,
                    domainOverride: "",
                    context: "system",
                    readOnly: false
                },
                controller: "domainFooterController",
                controllerAs: "ctrl",
                templateUrl: "app/settings/domain-settings/email-customization/email-footer.dlg.html",
                targetEvent: ev
            })
                .then(function (modalSuccess) {
                    if (vm.footerHtml !== modalSuccess) { vm.footerHtml = modalSuccess; form.$setDirty(); }
                });
        }

        //// Uploader
        function adjustLogoHover() {
            $(".profile-setting-logo #logo-hover").css({ width: $(".profile-setting-logo img").width() });
        }

        function createGuid() {
            vm.loginPreviewGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) { var r = Math.random() * 16 | 0, v = c == 'x' ? r : r & 0x3 | 0x8; return v.toString(16); });
            return vm.loginPreviewGuid;
        }

        function imagePreviewImage(imgSrc, file) {
            vm.file = file;

            if (vm.previewingLogin)
                uploadTempFile(true);

            vm.logoAction = "";
            vm.companyLogoUrl = imgSrc;
            vm.pageForm.$setDirty();
            vm.imagePendingSave = true;
            vm.logoChanged = true;
            $scope.$applyAsync();
        }

        function refreshImages() {
            var promises = [];
            promises.push($http.get("~/api/v1/companyinfo/logo-exists/"));

            $q.all(promises)
                .then(function (success) {
                    vm.companyLogoUrl = success[0].data ? (stSiteRoot + "api/v1/companyinfo/logo/") : undefined;
                    $scope.randomUrlString = (num++).toString();
                    $scope.$applyAsync();
                });
        }

        function removePicture(logo, ev) {
            var picture = logo ? "logo" : "favicon";

            if (picture == "logo") {
                vm.companyLogoUrl = null;
                vm.pendingRemoveImage = true;
                vm.imagePendingSave = false;

                preferencesStorage.setLocalParam("loginPreviewChanges", { imageGuid: "" });
                vm.logoChanged = true;
                vm.logoAction = "";
                vm.loginPreviewGuid = "";
            }
            vm.pageForm.$setDirty();
            /*
            $http.post("~/api/v1/companyinfo/" + picture+"-delete")
            .then(function () {
                refreshImages();
            }, errorHandling.report);
            */
        }

        function uploadFile(result, dropzone) {
            if (!result || result === -1 || result.code === 500) {
                errorHandling.report("ERROR_UPLOADING");
            } else {
                refreshImages();
            }
        }

        function uploaderInitImage(publicFunctions) {
            vm.uploaderFunctionsImage = publicFunctions;
        }

        function uploadTempFile(setStorage) {
            if (!vm.file)
                return $q.when();
            var promise = $http({
                method: 'POST',
                url: '~/api/v1/settings/sysadmin/temp-image/' + createGuid(),
                headers: {
                    'Content-Type': undefined
                },
                data: {
                    upload: vm.file
                },
                transformRequest: function (data, headersGetter) {
                    var formData = new FormData();
                    angular.forEach(data, function (value, key) {
                        formData.append(key, value);
                    });

                    var headers = headersGetter();
                    delete headers['Content-Type'];

                    return formData;
                }
            })
                .then(function () {
                    if (setStorage)
                        preferencesStorage.setLocalParam("loginPreviewChanges", { imageGuid: vm.loginPreviewGuid });
                }, errorHandling.report);

            vm.file = undefined;
            return promise;
        }

        //// On changed
        function customHelpUrlChanged() {
            preferencesStorage.setLocalParam("loginPreviewChanges", { customHelpUrl: vm.customHelpLinkEnabled ? vm.customHelpUrl : '' });
        }

        function customHelpTextChanged() {
            preferencesStorage.setLocalParam("loginPreviewChanges", { customHelpText: vm.customHelpLinkEnabled ? vm.customHelpText : '' });
        }

        function customHelpEnableChanged() {
            if (vm.customHelpLinkEnabled) {
                preferencesStorage.setLocalParam("loginPreviewChanges", { customHelpUrl: vm.customHelpUrl, customHelpText: vm.customHelpLinkEnabled });
            } else {
                preferencesStorage.setLocalParam("loginPreviewChanges", { customHelpUrl: '', customHelpText: '' });
            }
        }

        function customLoginTextChanged() {
            preferencesStorage.setLocalParam("loginPreviewChanges", { customTitle: vm.customLoginText });
        }

        function customLoginPageHtmlEnabledChanged() {
            if (vm.customLoginPageHtmlEnabled)
                preferencesStorage.setLocalParam("loginPreviewChanges", { customHtmlBlock: vm.loginPageHtml });
            else
                preferencesStorage.setLocalParam("loginPreviewChanges", { customHtmlBlock: "" });
        }

        function loginBackgroundColorChanged() {
            preferencesStorage.setLocalParam("loginPreviewChanges", { backgroundColor: vm.loginBackgroundColor });
        }

        function loginBackgroundImagePathChanged() {
            preferencesStorage.setLocalParam("loginPreviewChanges", { backgroundStillImage: "/dynamic/preview/background-of-the-day/" + utf8ToBase64(vm.loginBackgroundImagePath) });
        }

        function loginBackgroundOptionChanged() {
            if (vm.loginBackgroundOption === 2) {
                preferencesStorage.setLocalParam("loginPreviewChanges", { backgroundStillImage: "", backgroundColor: vm.loginBackgroundColor });
            } else if (vm.loginBackgroundOption === 1) {
                preferencesStorage.setLocalParam("loginPreviewChanges", { backgroundStillImage: "/dynamic/preview/background-of-the-day/" + utf8ToBase64(vm.loginBackgroundImagePath), backgroundColor: "" });
            } else
                preferencesStorage.setLocalParam("loginPreviewChanges", { backgroundStillImage: "/dynamic/preview/background-of-the-day/", backgroundColor: "" });
        }

        function setLocalStorageForPreview() {
            var backgroundStillImage = "";
            if (vm.loginBackgroundOption === 2) {
                backgroundStillImage = "";
            } else if (vm.loginBackgroundOption === 1) {
                backgroundStillImage = "/dynamic/preview/background-of-the-day/" + utf8ToBase64(vm.loginBackgroundImagePath);
            } else
                backgroundStillImage = "/dynamic/preview/background-of-the-day/";

            preferencesStorage.setLocalParam("loginPreviewChanges", {
                customTitle: vm.customLoginText,
                backgroundStillImage: backgroundStillImage,
                backgroundColor: vm.backgroundStillImage ? "" : vm.loginBackgroundColor,
                customHelpUrl: vm.customHelpLinkEnabled ? vm.customHelpUrl : '',
                customHelpText: vm.customHelpLinkEnabled ? vm.customHelpText : '',
                customHtmlBlock: vm.customLoginPageHtmlEnabled ? vm.loginPageHtml : "",
                imageGuid: vm.logoChanged ? vm.loginPreviewGuid : undefined,
                logoAction: vm.logoAction
            });
        }

        function autoCleanNewRule(ev, form) {
            $mdDialog
                .show({
                    locals: {
                        folderName: 'Deleted Items',
                        folders: vm.autoCleanFolders,
                        processGoal: 75,
                        processThreshold: 100,
                        thresholdDays: 30,
                        type: 0,
                        enabled: true,
                        newItem: true
                    },
                    controller: "userEditAutoCleanController",
                    controllerAs: "ctrl",
                    templateUrl: "app/settings/shared/edit-auto-clean.dlg.html",
                    targetEvent: ev
                })
                .then(onNewRuleOk, function () { });

            function onNewRuleOk(modalSuccess) {
                modalSuccess.rule.processGoalBytes = modalSuccess.rule.processGoalMb * 1024 * 1024;
                modalSuccess.rule.processThresholdBytes = modalSuccess.rule.processThresholdMb * 1024 * 1024;
                vm.autoCleanSystemRules.push(modalSuccess.rule);
                form.$setDirty();
            }
        };

        function autoCleanEditRule(ev, rule, form) {
            $mdDialog
                .show({
                    locals: {
                        folderName: rule.folderName,
                        folders: vm.autoCleanFolders,
                        processGoal: rule.processGoalMb,
                        processThreshold: rule.processThresholdMb,
                        thresholdDays: rule.thresholdDays,
                        type: rule.type,
                        enabled: rule.enabled,
                        newItem: false
                    },
                    controller: "userEditAutoCleanController",
                    controllerAs: "ctrl",
                    templateUrl: "app/settings/shared/edit-auto-clean.dlg.html",
                    targetEvent: ev
                })
                .then(onEditRuleOk, function () { });

            function onEditRuleOk(modalSuccess) {
                if (modalSuccess.deleteItem) {
                    autoCleanDeleteItem(rule, form);
                    return;
                }
                modalSuccess.rule.id = rule.id;
                modalSuccess.rule.processGoalBytes = modalSuccess.rule.processGoalMb * 1024 * 1024;
                modalSuccess.rule.processThresholdBytes = modalSuccess.rule.processThresholdMb * 1024 * 1024;

                var index = vm.autoCleanSystemRules.indexOf(rule);
                if (index !== -1)
                    vm.autoCleanSystemRules[index] = modalSuccess.rule;
                else
                    vm.autoCleanSystemRules.push(modalSuccess.rule);
                form.$setDirty();
            }
        };

        function autoCleanDeleteItem(rule, form) {
            var index = vm.autoCleanSystemRules.indexOf(rule);
            if (index != -1)
                vm.autoCleanSystemRules.splice(index, 1);
            form.$setDirty();
        };

        function autoCleanGetCleanBottomString(rule) {
            switch (rule.type) {
                case 0:
                    var over = $filter("bytes")(rule.processThresholdBytes, 1);
                    var reduce = $filter("bytes")(rule.processGoalBytes, 1);
                    return $translate.instant("AUTO_CLEAN_SUMMARY_SIZE", { over: over, reduce: reduce });
                case 1:
                    return $translate.instant("AUTO_CLEAN_SUMMARY_TIME_IN_FOLDER", { days: rule.thresholdDays });
                case 2:
                    return $translate.instant("AUTO_CLEAN_SUMMARY_MESSAGE_AGE", { days: rule.thresholdDays });
            }
        };

        function reindexDomainArchive() {
            var params = JSON.stringify({ messageArchiveName: vm.archiveRule.domain });
            var confirm = $mdDialog.confirmDeletion()
                .title($translate.instant("CONFIRMATION_REQUIRED"))
                .textContent($translate.instant("CONFIRMATIONS_REINDEX"))
                .ok($translate.instant("REINDEX"))
                .noWarn(true);

            $mdDialog
                .show(confirm)
                .then(onConfirm);
            function onConfirm() {
                $rootScope.spinner.show();
                $http
                    .post("~/api/v1/settings/sysadmin/start-domain-archive-reindex", params)
                    .then(successHandling.report, errorHandling.report)
                    .finally($rootScope.spinner.hide);
            }
        }

        function openArchiveRuleEditModal(ev) {
            return $mdDialog.show({
                locals: { rule: vm.archiveRule },
                controller: "systemMessageArchivingRuleModalController",
                controllerAs: "ctrl",
                templateUrl: "app/sysadmin/shared/modals/archive-rule.dlg.html",
                targetEvent: ev
            });
        }
        function editAchiveRule(ev) {
            openArchiveRuleEditModal(ev)
                .then(function (result) {
                    successHandling.report("SUCCESS");
                    refresh();
                }, function () { });
        }

        function disableArchivingRule(ev) {

            $http.get("~/api/v1/settings/sysadmin/domain-archive-rule/")
                .then(function (success) {
                    $mdDialog
                        .show({
                            locals: {
                                archivePath: success.data.rule.archivePath,
                                archiveSize: success.data.rule.archiveSize
                            },
                            controller: "archiveConfirmDisableDialogController",
                            controllerAs: "ctrl",
                            templateUrl: "app/sysadmin/shared/modals/archive-confirm-disable.dlg.html",
                            targetEvent: ev
                        })
                        .then(onConfirmed, function () { });

                }, function () { });

            function onConfirmed() {
                var params = {};
                var rule = {
                    archivePath: "",
                    domain: vm.systemArchiveRule.domain,
                    incoming: vm.systemArchiveRule.incoming,
                    outgoing: vm.systemArchiveRule.outgoing,
                    monthsToKeep: vm.systemArchiveRule.monthsToKeep,
                    disabled: true,
                };
                /**
                 * Currently we are not allowing deletions of existing archives
                 *	disabling an archive is synonymous with a detach; 
                 *	this may change especially at the domain level as we are not providing a way to remove this configuration
                 *	
                if (modalData.deleteData) {
                    params.toRemove = [rule.domain];
                } else {
                    params.rules = [rule];
                }
                 * */
                params.rules = [rule];
                $rootScope.spinner.show();
                $http
                    .post("~/api/v1/settings/sysadmin/domain-archive-rules", JSON.stringify(params))
                    .then(onDeleteSuccess, errorHandling.report)
                    .finally($rootScope.spinner.hide);

                function onDeleteSuccess() {
                    successHandling.report("SUCCESS");
                    activate();
                }
            }
        }

        function clearSearchTerm() {
            vm.countrySearch = '';
        }

        function deleteCountryFromList(index, form) {
            vm.blockedCountrySettings.countries.splice(index, 1);
            vm.countryListChanged();
            form.$setDirty();
        }

        function countryListChanged(form) {
            if (vm.blockedCountrySettings.countries.length == 0) {
                vm.blockedCountrySettings.countries.push('');
            } else {
                var tempList = vm.blockedCountrySettings.countries.filter((c, index) => {
                    return vm.blockedCountrySettings.countries.indexOf(c) === index;
                });

                if (vm.blockedCountrySettings.countries.length !== tempList.length) {
                    errorHandling.report("ERROR_DUPLICATE_COUNTRIES_ARE_NOT_ALLOWED");
                }
                vm.blockedCountrySettings.countries = tempList;

                if (vm.blockedCountrySettings.countries.length > 0) {
                    var empty = $.grep(vm.blockedCountrySettings.countries, function (item) { return item === ''; });
                    if (empty.length > 1) {
                        vm.blockedCountrySettings.countries = $.grep(vm.blockedCountrySettings.countries, function (item) { return item !== ''; });
                        vm.blockedCountrySettings.countries.push('');
                    } else if (empty.length === 0) {
                        vm.blockedCountrySettings.countries.push('');
                    }
                }
            }
        }

        function autoCleanAllowDomainOverrideChanged() {
            if (!vm.autoCleanSettings.autoCleanAllowDomainOverride)
                vm.autoCleanSettings.autoCleanAllowInbox = false;
        }

        vm.openExternalWhitelistModal = function (event, form) {
            $mdDialog
                .show({
                    locals: {
                        extensions: angular.copy(vm.settings.externalSendersSys.whitelist),
                        title: "KNOWN_EXTERNAL_DOMAINS",
                        isReadOnly: false
                    },
                    controller: "extensionBlacklistDialogController",
                    controllerAs: "ctrl",
                    templateUrl: "app/settings/shared/extension-blacklist.dlg.html",
                    targetEvent: event
                })
                .then(
                    function(success) {
                        var filtered = $.grep(success.extensions, value => (value || "").trim())
                        vm.settings.externalSendersSys.whitelist = filtered;
                        form.$setDirty();
                    },
                    function() {});
        }
    }

})();
